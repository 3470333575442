import React, {FC, useState, useEffect, useRef, useCallback} from 'react'
import styled from 'styled-components'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {ExpandMore} from '@material-ui/icons'
import {theme, Text, Row, Space, Icon} from 'ui'
import {observer} from 'mobx-react'
import useStore, {modulesStore, snackBar} from 'store'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import icons from 'components/Groups/groupCategories.json'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import type {ModuleType} from 'utils/types'
import {ModuleEnum, ProviderEnum} from 'utils/types'
import {validURL} from 'utils/validations'
import RichTextEditor from '../Messages/RichTextEditor'
import {
    ConfirmButtonSm,
    DeleteButtonSm,
    ButtonGroup, 
    DialogHeaderText, 
    InfoWrapper, 
    ModalText,
    ModalRow,
} from './styles'
import {PortalFormState} from './CommunitySettingsModal'

const ContainerDialog = styled.div`
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  display: flex;
  max-height: 900px;
`
const SelectContent = styled.div`
  align-items: center;
  flex-direction: row;
  display: flex;
`

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
`
const StyledTextField = styled(TextField)`
  width: 100%;
`
const IconBg = styled.div<{ selected: boolean }>`
  background: ${p => p.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.33rem;
  border-radius: 8px;
  margin-right: 35px;
  border-color: ${p => p.selected ? theme.color.select : theme.color.white};
  border-width: 4px;
  border-style: solid;

  body[dir=rtl] & {
    margin-right: unset;
    margin-left: 20px;
  }
`

enum ValidFields {
    information = 'information',
    shareEmail = 'shareEmail'
}

enum ValidLengthFields {
    information = 2000,
    shareEmail = 1000
}

const IconSelect: FC<{ icons: any, onChange: any, givenSelectedIcon: any }> = ({
                                                                                   icons,
                                                                                   onChange,
                                                                                   givenSelectedIcon
                                                                               }) => {
    const [selectedIcon, setSelectedIcon] = useState<string | undefined>(givenSelectedIcon)
    const clickHandler = (iconOptions: any) => {
        setSelectedIcon(iconOptions.icon)
        onChange(iconOptions)
    }
    return (
        <IconsContainer>
            {
                icons.map((iconOptions: any) => (
                    <IconBg selected={selectedIcon == iconOptions.icon} color={iconOptions.iconColor}
                            onClick={() => clickHandler(iconOptions)}>
                        <Icon name={iconOptions.icon}/>
                    </IconBg>
                ))
            }
        </IconsContainer>
    )
}

const StyledUserConditional = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin: 10px;
`

interface PortalSectionProps {
    onPortalSave: () => Promise<void>
    isPortalFormChanged: boolean
    savePortalChanges: boolean
    portalFormState: PortalFormState
    onPortalToggleVisible: (e: boolean) => void
    onPortalChangeProvider: (value: string | null) => void
    onChangeLink: (value: string) => void
    onChangeOrgId: (value: string) => void
    ProviderTypes: { value: ProviderEnum, text: string }[]
    onResetPortalField: () => void
}

const UserConditional = ({
                             checked,
                             handleChange,
                             text
                         }: { checked: boolean, handleChange: () => void, text: string }) => {
    return (
        <StyledUserConditional>
            <Text>{text}</Text>
            <Switch checked={checked} onChange={handleChange} name={text} color="primary"/>
        </StyledUserConditional>
    )
}
const PortalSection: FC<PortalSectionProps> = (props) => {
    const {t} = useTranslation('budget')
    const {modulesStore, orgs, uiStore} = useStore()
    const {
        onPortalSave,
        isPortalFormChanged,
        savePortalChanges,
        portalFormState,
        onPortalToggleVisible,
        onPortalChangeProvider,
        onChangeLink,
        onChangeOrgId,
        ProviderTypes,
        onResetPortalField,
    } = props
    const types = [
        {value: ModuleEnum.THIRD, text: t`third`},
        {value: ModuleEnum.DELIVERY, text: t`ordering`},
        {value: ModuleEnum.ID_QR_CODE, text: ModuleEnum.ID_QR_CODE}
    ]
    const communityQrModule = modulesStore.communityModules.find((module) => module.type === ModuleEnum.ID_QR_CODE);

    const clearSection = () => {
        modulesStore.setupModuleModalPortalMode('portal')
        setType(modulesStore?.currentModule?.type || null)
        onResetPortalField()
    }
    const [selectedIcon, setSelectedIcon] = useState<string | undefined | null>(undefined)

    const onChangeIcon = (icon: any) => {
        setSelectedIcon(icon.icon)
        setButtonColor(icon.iconColor)
    }
    const [type, setType] = useState<null | ModuleEnum>(null)
    const [buttonColor, setButtonColor] = useState<string | null>('')
    const [title, setTitle] = useState<string | null>(null)
    const [description, setDescription] = useState<string | null>(null)
    const [iconText, setIconText] = useState<string | null>(null)
    const [editModuleText, setEditModuleText] = useState<string | null>('')
    const [share, setShare] = useState(false);
    const [shareEmail, setShareEmail] = useState<string | null>(null);
    const [information, setInformation] = useState<string>('')
    const isValidFields = useRef<Array<string>>([]);

    const handleChangeValidFields = ({str, field}: { str: string, field: ValidFields }) => {
        const isField = isValidFields.current.some((item) => item === field)

        if (str.length > ValidLengthFields[field] && !isField) {
            isValidFields.current = [...isValidFields.current, field]
        }

        if (isValidFields.current.length > 0 && str.length <= ValidLengthFields[field]) {
            const d = isValidFields.current.filter((item) => item != field)
            isValidFields.current = d
        }
    }

    const handleChangeShareEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShareEmail(event.target.value);
        handleChangeValidFields({str: event.target.value, field: ValidFields.shareEmail});
    }

    useEffect(() => {
        modulesStore.setupModuleModalPortalMode('portal')
    }, [])

    useEffect(() => {
        if (modulesStore?.currentModule) {
            setTitle(modulesStore?.currentModule?.title)
            setDescription(modulesStore?.currentModule?.description)
            setIconText(modulesStore.currentModule?.icon_text)
            setButtonColor(modulesStore.currentModule?.icon_color)
            setType(modulesStore?.currentModule?.type)
            setSelectedIcon(modulesStore?.currentModule?.icon_name)
            setShareEmail(modulesStore?.currentModule.shareEmail)
            setShare(!!modulesStore?.currentModule.share)

            if (modulesStore?.currentModule.information) {
                const parseInformation = JSON.parse(modulesStore?.currentModule?.information);

                setInformation(parseInformation[0])
            }

            const newModule = modulesStore?.currentModule?.module_id
            if (modulesStore?.currentModule.type === ModuleEnum.DELIVERY) {
                setEditModuleText(`${newModule ? t`editModule` : ''} ${t`ordering`}`)
            } else if (modulesStore?.currentModule.type === ModuleEnum.THIRD) {
                setEditModuleText(`${newModule ? t`editModule` : ''} ${t`third`}`)
            } else if (modulesStore?.currentModule.type === ModuleEnum.PORTAL) {
                setEditModuleText(`${newModule ? t`editModule` : t`app:add2`} ${t`portal`}`)
            } else if (modulesStore?.currentModule.type === ModuleEnum.SUPPORT) {
                setEditModuleText(`${newModule ? t`editModule` : t`app:add2`} ${t`serviceCall:services`}`)
            } else if (modulesStore?.currentModule.type === ModuleEnum.SLIKA) {
                setEditModuleText(`${newModule ? t`editModule` : t`app:add2`} ${t`slika`}`)
            }
        }

    }, [modulesStore.currentModule])


    useEffect(() => {
        if (savePortalChanges) {
            (async () => {
                await onSave()
            })()
        }
    }, [savePortalChanges])

    const onSave = async () => {
        if (canSave()) {
            return
        }
        if (!isPortalFormChanged) {
            return;
        }

        if (isValidFields.current.length) {
            const currentField = isValidFields.current[0]
            const length = ValidLengthFields[currentField as ValidFields]

            snackBar.showError(`שדה ${t(currentField)} לא יכול להיות ארוך מ-${length} תווים`)
            return
        }
        if (type != null && portalFormState.provider !== '' ) {
            const strInformation = JSON.stringify({0: information})
            const module: ModuleType = {
                community_ref: type === ModuleEnum.THIRD ? null : modulesStore?.currentModule?.community_ref || orgs.currentOrgId,
                module_id: modulesStore?.currentModule?.module_id || null,
                type: type, //(enum: portal, budget, third, reservations, voting) + nut null
                title: title, //string && nullable
                description: description, //string && nullable
                external_url: portalFormState.link, //string && nullable
                icon_name: selectedIcon, //string && nullable
                icon_text: iconText,
                icon_color: buttonColor,
                org_id: portalFormState.orgId,
                provider: type === ModuleEnum.ID_QR_CODE ? ProviderEnum.MEKOME_QR : portalFormState.provider,
                information: strInformation,
                share,
                shareEmail,
            }

            if (modulesStore?.currentModule?.module_id) {
                await modulesStore.updateModule(module)
            } else {
                await modulesStore.addNewModule(module)
            }
        }
        await onPortalSave()

        clearSection()

    }

    const onDelete = async () => {
        if (!modulesStore?.currentModule?.module_id && !communityQrModule?.module_id) {
            return
        }

        let deleteText = ''
        switch (type) {
            case ModuleEnum.THIRD:
                deleteText = t`third`
                break;
            case ModuleEnum.DELIVERY:
                deleteText = t`ordering`
                break;
            case ModuleEnum.SLIKA:
                deleteText = t`slika`
                break;
            case ModuleEnum.SUPPORT:
                deleteText = t`serviceCall:services`
                break;
            case ModuleEnum.PORTAL:
                deleteText = t`portal`
                break;
            case ModuleEnum.ID_QR_CODE:
                deleteText = ModuleEnum.ID_QR_CODE
                break;
        }

        uiStore.openWarningModal({
            text: `${t`remove:moduleRemove`} ${deleteText}`,
            secondText: (modulesStore?.currentModule?.title && modulesStore?.currentModule?.title != '') ? `${t`remove:moduleName`} ${modulesStore?.currentModule?.title}` : '',
            okAction: () => {
                if (modulesStore?.currentModule) {
                    modulesStore.deleteModule(modulesStore?.currentModule?.module_id)
                } else if (communityQrModule) {
                    modulesStore.deleteModule(communityQrModule.module_id)
                }
                clearSection()
            }
        })

    }
    const canSave = () => {
        if (isPortalFormChanged) {
            if (portalFormState.provider === ProviderEnum.NONE || !portalFormState.provider) {
                return false
            }
            if (type === ModuleEnum.ID_QR_CODE) {
                return !!communityQrModule
            }
            if (type === ModuleEnum.DELIVERY && title && portalFormState.link && validURL(portalFormState.link) && selectedIcon) {
                return false
            }
            if (type === ModuleEnum.THIRD && title && description && iconText && buttonColor != '') {
                return false
            }
            if (type === ModuleEnum.PORTAL && portalFormState.link && validURL(portalFormState.link)) {
                return false
            }
            if (type === ModuleEnum.SUPPORT && portalFormState.link && validURL(portalFormState.link)) {
                return false
            }
            if (type === ModuleEnum.SLIKA && portalFormState.orgId) {
                return false
            }
            return true
        }
        return true
    }
    const canCreateService = !modulesStore.communityModules?.find(el => el.type === ModuleEnum.SUPPORT)//true if theres no support

    if (canCreateService) {
        types.push({value: ModuleEnum.SUPPORT, text: t`serviceCall:services`})
    }

    const handleEditorChange = ({text}: { text: string }) => {
        setInformation(text)
        handleChangeValidFields({str: text, field: ValidFields.information});
    }

    return (
        <>
         <ModalRow>
            <DialogHeaderText>{modulesStore?.currentModule ? editModuleText : t`addNewModule`}</DialogHeaderText>
                <ButtonGroup>
                {!!modulesStore?.currentModule || (type === ModuleEnum.ID_QR_CODE && !!communityQrModule) ?
                    <DeleteButtonSm disabled={!modulesStore?.currentModule?.module_id}
                                  onClick={onDelete}>{t`common:deletion`}</DeleteButtonSm> : <div/>}

                <ConfirmButtonSm disabled={canSave()} onClick={onSave}>{t`save`}</ConfirmButtonSm>
               </ButtonGroup>    
            </ModalRow>
            <InfoWrapper style={{justifyContent: 'start'}}>
                <ModalText>{t`communitySettings:visible`}:</ModalText>
                <Space/>
                <Switch
                    color="primary"
                    value={true}
                    onChange={({target}) => {
                        onPortalToggleVisible(target.checked)
                    }}
                    checked={portalFormState.isVisible}
                />
            </InfoWrapper>
            <ContainerDialog>
                {!modulesStore?.currentModule && <Autocomplete
                    value={types.filter(i => i.value === type)[1]}
                    onChange={(event, value) => {
                        setType(value?.value || null)
                    }}
                    id="combo-box-demo"
                    options={types}
                    placeholder={t`type`}
                    getOptionLabel={(option) => option.text}
                    style={{width: 300}}
                    renderInput={(params) => <TextField {...params} value={type} label={t`type`} variant="outlined"/>}
                />}
                <Space height={1}/>
                {type && type !== ModuleEnum.ID_QR_CODE && <>
                    {(type === ModuleEnum.PORTAL || type === ModuleEnum.SUPPORT) && <Autocomplete
                        value={ProviderTypes.filter(i => i.value === portalFormState.provider)[0]}
                        onChange={(event, value) => {
                            onPortalChangeProvider(value?.value || null)
                        }}
                        id="combo-box-provider"
                        options={ProviderTypes}
                        placeholder={t`provider`}
                        getOptionLabel={(option) => option.text}
                        style={{width: '100%'}}
                        renderInput={(params) => <TextField {...params} value={type} label={t`provider`}
                                                            variant="outlined"/>}
                    />}
                    <Space height={1}/>
                    {(type === ModuleEnum.SLIKA || type === ModuleEnum.PORTAL || type === ModuleEnum.SUPPORT) && portalFormState.provider != ProviderEnum.MEKOME &&
                        <StyledTextField
                            value={portalFormState.orgId}
                            id="outlined-basic"
                            label={t`orgId`} variant="outlined"
                            onChange={(event) => onChangeOrgId(event.target.value)}
                        />}
                    {type != ModuleEnum.PORTAL && type != ModuleEnum.SUPPORT && type != ModuleEnum.SLIKA && (
                        <StyledTextField
                            value={title}
                            id="outlined-basic"
                            label={t`title`} variant="outlined"
                            onChange={(event) => setTitle(event.target.value)}
                        />
                    )
                    }
                    {type === ModuleEnum.THIRD &&
                        <>
                            <Space width={1}/>
                            {description ? <Text
                                style={{alignSelf: 'flex-start'}}
                                textcolor={theme.color.black + 99}
                                weight={'normal'}
                                size={'xs'}
                            >{t`description`}</Text> : <div style={{height: 18}}/>}
                            <TextareaAutosize
                                style={{width: '100%', minHeight: '80px'}}
                                rowsMin={4}
                                defaultValue={description}
                                placeholder={t`description`}
                                variant="outlined"
                                onChange={(event) => setDescription(event.target.value)}

                            />
                        </>
                    }
                    <Space height={1}/>
                    {type != ModuleEnum.SLIKA && <StyledTextField
                        value={portalFormState.link}
                        style={{direction: 'ltr', textAlign: portalFormState.link ? 'start' : 'end'}}
                        id="outlined-basic"
                        label={t`url`}
                        variant="outlined"
                        onChange={(event) => onChangeLink(event.target.value)}
                    />}
                    <Space height={1}/>
                    {/* {type === 'third' && <Row style={{ alignItems: 'flex-start', }}> */}
                    {type === ModuleEnum.THIRD && <StyledTextField
                        value={iconText}
                        id="outlined-basic"
                        label={t`iconText`}
                        variant="outlined"
                        onChange={(event) => setIconText(event.target.value)}
                    />}
                    <Space width={1}/>
                    {type != ModuleEnum.PORTAL && type != ModuleEnum.SUPPORT && type != ModuleEnum.SLIKA &&
                        <StyledTextField
                            style={{direction: 'ltr', textAlign: buttonColor === '' ? 'end' : 'start'}}
                            value={buttonColor}
                            id="outlined-basic"
                            label={type === 'third' ? t`buttonColor` : t`iconColor`}
                            variant="outlined"
                            onChange={(event) => setButtonColor(event.target.value)}
                        />}
                    {type != ModuleEnum.PORTAL && type != ModuleEnum.SUPPORT && type != ModuleEnum.SLIKA && (
                        <>
                            <Space width={1}/>
                            <StyledTextField
                                value={shareEmail || ''}
                                style={{direction: 'ltr', textAlign: !portalFormState.link ? 'start' : 'end'}}
                                id="outlined-basic"
                                label={t`shareEmail`}
                                variant="outlined"
                                name="shareEmail"
                                onChange={handleChangeShareEmail}
                            />
                            <Space width={1}/>
                            <RichTextEditor
                                style={{width: '100%', minHeight: 200}}
                                value={information}
                                placeholder={t`information`}
                                onChange={handleEditorChange}
                            />
                            <Space width={1}/>
                            <UserConditional checked={share} text={t`share`}
                                             handleChange={() => setShare(prevState => !prevState)}/>
                        </>
                    )}
                    <Space width={1}/>

                    {type === ModuleEnum.DELIVERY && <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <SelectContent>
                                <Space width={1}/>
                                {selectedIcon && <IconBg
                                    selected={false}
                                    color={buttonColor}
                                >
                                    <Icon name={selectedIcon}/>
                                </IconBg>}
                                <Text>{selectedIcon ? t`switchIcon` : t`icon`}</Text>
                            </SelectContent>
                        </AccordionSummary>
                        <AccordionDetails>
                            <IconSelect
                                icons={icons}
                                onChange={onChangeIcon}
                                givenSelectedIcon={selectedIcon}/>
                        </AccordionDetails>
                    </Accordion>}
                </>}
            </ContainerDialog>
            
        </>
    )
}

export default observer(PortalSection)


