import { observable, action, computed, IObservableArray, reaction } from 'mobx'
import api from 'service/api'
import { ModuleEnum, ModuleType, ProviderEnum, FeatureData, Feature, FeatureIds } from 'utils/types'
import orgs from 'store/orgs.store'
import { promiseMaker } from 'utils/promiseMaker'
import veryLocalStorage, { storageKeys } from 'utils/vls'
import {  userIsSuperAdmin, userIsDirector } from 'utils/roles'


class ModulesStore {

  constructor() {
    reaction(() => orgs.currentOrgId, async (orgId) => {
      if (orgId) {
        this.setLoading(true)
        await this.getCommunityModulesAndFeaturesAndOpen(orgId)

        if (userIsDirector()) {
          await this.getCommunityDirectorModules(orgId) 
        }
        
        //await this.initLocationFeature(orgId)
      }
    })
  }

  @observable communityModules: IObservableArray<ModuleType> = observable([])
  @observable communityDirectorModules: IObservableArray<ModuleType> = observable([])
  @observable communityFeatures: IObservableArray<FeatureData> = observable([])
  @observable moduleModalisOpen: boolean = false
  @observable isEnabledLocationFeature: boolean = false
  @observable isEnabledEmergencyFeature: boolean = false
  @observable isEnabledEmergencyToggle: boolean = false
  @observable currentModule: undefined | ModuleType = undefined
  @observable isLoading = true
  @observable initialized: {
    promise: Promise<any>;
    res: any;
    rej: any;
  } = promiseMaker()
  @computed get budgets() {
    return this.communityModules?.filter(el => el.community_ref === orgs.currentOrgId && el.type === ModuleEnum.SLIKA) || []
  }

  @action getBudgets(type = ModuleEnum.PORTAL) {
    return this.communityModules?.filter(el => el.community_ref === orgs.currentOrgId && el.type === type) || []
  }
  @action setLoading(bool: boolean) {
    this.isLoading = bool
  }
  @action openModuleModal() {
    this.currentModule = undefined
    this.moduleModalisOpen = true
  }
  @action openModuleModalEditMode(id: number) {
    if (this.communityModules != null && this.communityModules.length > 0) {
      const current = this.communityModules.filter(i => i.module_id === id)
      this.currentModule = current[0]
      this.moduleModalisOpen = true
    }
  }

  @action  initLocationFeature = async (id: number) => {
    const locationFeature = this
          .communityFeatures
          .find(el => el.featureName.toLowerCase() === 'location')
    
    if (locationFeature === undefined) {
        const locationFeature: FeatureData = {
            communityId: id,
            enabled: false,
            featureName: 'Location',
            id: FeatureIds.MAP,
            status: 2,
        }

        await orgs.updateCommunity(id, [...this.communityFeatures, locationFeature])
        await this.getCommunityModulesAndFeatures(id)
    }  
}

  @action setStatusOfLocationFeature = () => {
    const locatioFeature = this
      .communityFeatures
      .find(el => el.featureName.toLowerCase() === 'location')

      this.isEnabledLocationFeature = locatioFeature !== undefined  && locatioFeature.enabled
  }

  @action setStatusOfEmergencyToggle = () => {
    const emergencyFeature = this
      .communityFeatures
      .find(el => el.featureName.toLowerCase() === 'emergency')

    const isEmergencyFeatureEnabled = emergencyFeature !== undefined && emergencyFeature.enabled
    
    let isEmergencyManagerModuleEnabled = false

    if (userIsSuperAdmin()) {
      const emergencyModule = this
        .communityModules
        .find(el => el.type === ModuleEnum.EMERGENCY_MANAGER)  
  
      isEmergencyManagerModuleEnabled = emergencyModule !== undefined
    } else if (userIsDirector()) {
      const emergencyModule = this
        .communityDirectorModules
        .find(el => el.type === ModuleEnum.EMERGENCY_MANAGER)    

      isEmergencyManagerModuleEnabled = emergencyModule !== undefined && emergencyModule.directorPermission
    }

    this.isEnabledEmergencyToggle = isEmergencyFeatureEnabled && isEmergencyManagerModuleEnabled
  }
  
  @action setStatusOfEmergencyFeature = () => {
    const emergencyFeature = this
      .communityFeatures
      .find(el => el.featureName.toLowerCase() === 'emergency')
    const isEmergencyFeatureEnabled = emergencyFeature !== undefined && emergencyFeature.enabled

    const emergencyModule = this
      .communityModules
      .find(el => el.type === ModuleEnum.EMERGENCY_MANAGER)  
    const isEmergencyModuleEnabled = emergencyModule !== undefined && emergencyModule.enable
    
    this.isEnabledEmergencyFeature = isEmergencyFeatureEnabled && isEmergencyModuleEnabled  
  }

  @action setCurrentModule(id: number) {
    if (this.communityModules != null && this.communityModules.length > 0) {
      const current = this.communityModules.filter(i => i.module_id === id)
      this.currentModule = current[0]
    }
  }

  @action openModuleEditPortal(type: string) {
    const current = this.communityModules.filter(i => i.type === type)
    this.currentModule = current[0]
    this.moduleModalisOpen = true
  }

  @action setupModuleModalPortalMode(type: string) {
    const current = this.communityModules.filter(i => i.type === type)
    if (current[0]) {
      this.currentModule = current[0]
      return
    }
    this.currentModule = {
      module_id: null,
      type: ModuleEnum.PORTAL,
      external_url: null,
      title: null,
      description: null,
      community_ref: orgs.currentOrgId,
      icon_color: null,
      icon_text: null,
      icon_name: null,
      icon_text_settings: null,
      icon: null,
      provider: ''
    }
  }
  @action openModuleModalSlikaMode() {

    this.currentModule = {
      module_id: null,
      type: ModuleEnum.SLIKA,
      external_url: null,
      title: null,
      description: null,
      community_ref: orgs.currentOrgId,
      icon_color: null,
      icon_text: null,
      icon_name: null,
      icon_text_settings: null,
      icon: null,
      provider: 'slika'
    }
    this.moduleModalisOpen = true
  }
  @action closeModuleModal() {
    this.moduleModalisOpen = false

  }
  @action clearCurrent() {
    this.currentModule = undefined
  }

  @action setCurrentCommunityFeatures(features: Feature[], communityId: number) {
    const currentCommunityFeatures = features.find((feature) => feature.communityId === communityId);
    this.communityFeatures.replace(currentCommunityFeatures?.features || []);
  }

  @action async getCommunityDirectorModules(id: number) {
    const response = await api.getCommunityDirectorModules(id);

    const { modules } = response;
    this.communityDirectorModules.replace(modules)
    this.setStatusOfEmergencyToggle()
  } 

  @action async openKehilaNetModule(moduleId, communityId, externalUrl) {
    try {   
      const response = await api.OpenModule(moduleId, communityId, ModuleEnum.KEHILANET, ProviderEnum.KEHILANET)
      
      if (response && response?.data) {
        const { data } = response
        window.open(`${externalUrl}&token=${data}`, '_blank');
      }
      
    } catch(e) {
      console.log('error:', e)
    }
  }

  @action async getCommunityModulesAndFeatures(id: number) {
    try {
      const response = await api.getCommunityModules(id);
      const { communityModules, features } = response;

      this.communityModules.replace(communityModules)
      this.setCurrentCommunityFeatures(features, id)
      this.setStatusOfLocationFeature()
      this.setStatusOfEmergencyFeature()
      this.setStatusOfEmergencyToggle()
      this.initialized.res(true)
      this.isLoading = false;
    } catch (e) {
      this.initialized.rej(e)
      console.log('error:', e)
    }
  }

  @action async getCommunityModulesAndFeaturesAndOpen(id: number) {
    try {
      const response = await api.getCommunityModules(id);
      const { communityModules, features } = response;
      
      const maofs = communityModules.filter((el: ModuleType) => el.type === ModuleEnum.PORTAL && el.provider === ProviderEnum.MAOF);
      await Promise.all((maofs as any[]).map(async el => {
        await api.OpenModule(el.module_id, id, ModuleEnum.PORTAL, ProviderEnum.MAOF, (authUrl) => {
          el.external_url = el.external_url + `${el.external_url?.includes('?') ? '&' : '?'}` + authUrl.replace(/^\&|\?/, '')
        })
      }))
      
      const mekomes = communityModules.filter((el: ModuleType) => el.type === ModuleEnum.PORTAL && el.provider === ProviderEnum.MEKOME);
      await Promise.all((mekomes as any[]).map(async (el: ModuleType) => {
        // const tokenCommunity: { [orgId: number]: string } = veryLocalStorage.get(storageKeys.tokenCommunity)
        if (el.community_ref/* && !tokenCommunity[el.community_ref]*/) {
          await api.OpenModule(el.module_id, id, ModuleEnum.PORTAL, ProviderEnum.MEKOME, ((token: string) => {
            el.external_url = el.external_url + `${el.external_url?.includes('?') ? '&' : '?'}${token}&source=web`
          }))
        }
      }))

      this.communityModules.replace(communityModules)
      this.setCurrentCommunityFeatures(features, id)
      this.setStatusOfLocationFeature()
      this.setStatusOfEmergencyFeature()
      this.setStatusOfEmergencyToggle()
      this.initialized.res(true)
      this.isLoading = false;
    } catch (e) {
      this.initialized.rej(e)
      console.log('error:', e)
    }
  }

  @action async addNewModule(module: ModuleType) {
    this.communityModules.push(module)
    const index = this.communityModules.length - 1
    const response = await api.addCommunityModules(module)
    if (response.statusCode?.toLowerCase() === 'ok') {
      this.communityModules[index].module_id = response.module_id
    }
  }

  @action async deleteModule(id: number) {
    this.communityModules.replace(this.communityModules.filter(el => el.module_id != id))
    await api.deleteCommunityModules(id)
    // this.getCommunityModules(orgs.currentOrgId || 1)
  }

  @action async updateModule(module: ModuleType) {
    const foundIndex = this.communityModules.findIndex(el => el.module_id === module.module_id)
    if (foundIndex != -1) {
      this.communityModules[foundIndex] = module
    }
    const response = await api.updateCommunityModules(module)
    // this.communityModules = response.modules
    // this.getCommunityModules(orgs.currentOrgId || 1)
  }

  @action async updateModuleByDirector(module: ModuleType) {
    const foundIndex = this.communityModules.findIndex(el => el.module_id === module.module_id)
    if (foundIndex != -1) {
      this.communityModules[foundIndex] = module
    }

    const response = await api.updateCommunityModuleByDirector(module)
    await this.getCommunityModulesAndFeatures(orgs.currentOrgId)
  }

  @action async sendClickModule( data : { moduleId: number, communityId: number}) {
    const response = await api.sendClickAfterExpand(data);
  }
  @action async shareInformation( data : { moduleId: number, communityId: number}) {
    const response = await api.shareInformation(data);
  }
}

const modules = new ModulesStore()
export default modules
