import React, { FC } from 'react'
import { MenuItem, Select, Menu as MuiMenu } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { theme } from '../../ui'
import styled from 'styled-components'
import { Menu } from '@material-ui/icons'
import useStore from 'store'

const Container = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  margin-top:5px;
  cursor:pointer;
`
const SuperUserMenu: FC = () => {
    const { t } = useTranslation('superUserMenu')
    const { orgs, modulesStore, csvStore, user: userStore, members: membersStore } = useStore()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const openActionMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const createCommunity = () => {
        handleClose()
        orgs.openNewCommunityModal()
    }
    const createModule = () => {
        handleClose()
        modulesStore.openModuleModal()
    }

    const openCsv = () => {
        handleClose()
        csvStore.openCSV()
    }

    const openCsvSocialNumberList = () => {
        handleClose()
        csvStore.handleCSVMapSocialIdwithCntactModal(true)
    }

    const openCommunitySettingsModal = () => {
        handleClose()
        orgs.setShowCommunitySettingsModal(true)
    }

    const openMapSettingsModal = () =>  {
        handleClose()
        orgs.setShowMapSettingsModal(true)
    }
    
    const openListUsersToRemoveModal = () => {
        handleClose()
        userStore.setShowUserListToRemoveModal(true)
    }

    return (
        <>
            <Container
                onClick={openActionMenu}
            >
                <Menu
                    style={{ color: 'white' }}
                    color={'primary'}
                >
                </Menu>
            </Container>

            <MuiMenu
                id='super-user-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onBackdropClick={handleClose}
            >
                <MenuItem
                    onClick={createCommunity}
                    value='he'>{t`createNewCommunity`}
                </MenuItem>
                <MenuItem
                    onClick={createModule}
                    value='en'>{t`createNewModule`}
                </MenuItem>
                <MenuItem
                    onClick={openCsv}
                    value='en'>{t`members:addMany`}
                </MenuItem>
                <MenuItem
                    onClick={openCsvSocialNumberList}
                    value='en'>{t`mapSociaNumber`}
                </MenuItem>
                <MenuItem
                    onClick={openCommunitySettingsModal}
                    value='en'>
                    {t`communitySettings`}
                </MenuItem>
                <MenuItem
                    onClick={openMapSettingsModal}
                    value='en'>
                    {t`mapSettings`}
                </MenuItem>

                {membersStore.orgMembersSorted.length > 0 && 
                  <MenuItem
                      style={{ color: theme.color.red }}
                      onClick={openListUsersToRemoveModal}
                      value='en'>
                      {t`removeListOfUsers`}
                  </MenuItem>}
            </MuiMenu>
        </>
    )
}

export default SuperUserMenu
