import React from 'react'
import { observable, action, computed, reaction, toJS, IObservableArray, when, runInAction } from 'mobx'
import user from './user.store'
import modulesStore from 'store/modulesStore.store'
import api from 'service/api'
import type { ID, Provider, UserCommunity, UserGroup, Brand } from 'utils/types'
import vls, { storageKeys } from 'utils/vls'
import { members } from 'store'

import { Role } from 'utils/models'
import { userIsDirectorOrOperator, userIsSuperAdmin } from "utils/roles";
import {CommunityUpdateReq} from "utils/types";
import {CommunityFormState} from '../components/CommunitySettingsModal/CommunitySettingsModal'

class OrgsStore {

  constructor() {
    reaction(
      () => user.user?.user_id,
      (id) => {
        if (id) {
          this.initOrgs()
        }
        else {
          this.clear()
        }
        // id && this.initOrgs()
      },
      { fireImmediately: true }
    )

    reaction(
      () => this.currentOrgId,
      id => {
        vls.set(storageKeys.currentCommunityId, id)
        this.getCommunitySettings(id)
      }
    )
    // reaction(
    //   () => this.currentOrgId,
    //   id => {
    //     if (id) {
    //       this.getProviders(id)
    //     }
    //   }
    // )
  }

  @observable orgs: { [key: string]: UserCommunity } = {}
  @observable brands: { [key: string]: Brand } = {}

  @observable currentOrgId?: number = undefined

  @observable currentAdminOrg?: UserCommunity = undefined

  @observable currentBrandId?: number = undefined

  @observable settings: any = {} 

  @computed get currentOrg() { return this.orgs[`${this.currentOrgId}`] || {} }
  @computed get currentBrand() { return this.brands[`${this.currentBrandId}`] || {} }

  @action setCurrentOrgId = (id?: number) => {
    this.currentOrgId = id
  }

  @action setCurrentAdminOrg = (userCommunity?: UserCommunity) => {
    this.currentAdminOrg = userCommunity
  }

  @action setCurrentBrandId = (id?: number) => {
    this.currentBrandId = id
  }

  @observable data: IObservableArray<UserCommunity> = observable([])
  @observable brandsData: IObservableArray<Brand> = observable([])

  @action initOrgs = async () => {
    let data: UserCommunity[] = await api.getCommunities() || []
    let brandsData: Brand[] = [];
    // data = data.filter(i => i.is_enabled === true)
    this.data.replace(data)
    this.orgs = data.reduce((obj, curr) => ({ ...obj, [curr.id]: curr }), {})

    const currOrgIdFromVls = vls.get(storageKeys.currentCommunityId)
    const currOrgId = data.find(org => org.id === currOrgIdFromVls) ? currOrgIdFromVls : data[0]?.id
    this.setCurrentOrgId(currOrgId)
    /// Load Brands for Super Admin
    if (user.user?.role === Role.ADMIN) {
      brandsData = await api.getBrands() || []
      this.brandsData.replace(brandsData)
      this.brands = brandsData.reduce((obj, curr) => ({ ...obj, [curr.id]: curr }), {})
      const currBrandId = brandsData.find(brand => brand.id === this.currentOrg.brandId)?.id
      this.setCurrentBrandId(currBrandId)
      this.getCommunity()
    }
    this.setInitialized(true)
  }

  @observable initialized: boolean = false

  @action setInitialized = (value: boolean) => this.initialized = value

  @action setLocalCommunityGroups = (communityId: number, userGroups: UserGroup[]) => {
    if (this.orgs[communityId]) {
      this.orgs[communityId].groups = [...userGroups]
    }
  }

  @action getCommunity = async () => {
    if (this.currentOrgId && user.user?.role === Role.ADMIN) {
      this.setCurrentAdminOrg(await api.getCommunity(this.currentOrgId))
    }
  }

  @action handleSearchUpdate = async (orgId: number, brandId: number) => {
    this.setCurrentOrgId(orgId)
    await this.getCommunity()
    this.setCurrentBrandId(brandId)
  }

  @computed get currentCommunity() {
    return this.orgs[String(this.currentOrgId)] || {}
  }

  @observable selectedSidebarIds: string[] = []
  @observable expandedSidebarIds: string[] = []

  @action onTreeCommunityClick = (communityId: string) => {
    if (this.expandedSidebarIds.includes(communityId)) {
      // on collapse
      this.expandedSidebarIds = []
    } else {
      // on expand
      this.getCommunityGroups(communityId)
      this.expandedSidebarIds = [communityId]
    }
  }

  @action onTreeGroupClick = (communityId: string, groupId: string | null) => {
    this.selectedSidebarIds = [communityId]
    groupId && this.selectedSidebarIds.push(groupId)
    this.setCurrentOrgId(Number(communityId))
  }

  @action getCommunityGroups = async (communityId: string) => {
    const groups = await api.getGroups(communityId)
    this.setLocalCommunityGroups(Number(communityId), groups)
    return groups
  }

  @action setCommunitySettings = (data: any) => {
    this.settings = {
      accuracy: data.accuracy,
      enabled: data.enabled,
      fromKM: data.fromKM,
      toKM: data.toKM,
      lat: data.lat,
      lng: data.lng,
      zoom: data.zoom,
      moreInformation: data.moreInformation,
      geoData: data.geoData,
      
    }
 }

  @action getCommunitySettings = async (communityId: number) => {
    if(this.currentOrg?.is_director || this.currentOrg?.is_operator || user.user.role === Role.ADMIN) {
      const settings = await api.getCommunitySettings(communityId)
      this.setCommunitySettings(settings)
      return settings
    } else {
      const settings = {
        accuracy: null,
        enabled: null,
        emergencyEnabled: null,
        fromKM: null,
        toKM: null,
        lat: null,
        lng: null,
        zoom: null,
        moreInformation: null,
        geoData: ''
      }

      this.setCommunitySettings(settings)
      return settings
    }
  }

  @action updateCommunitySettings = async (communityId: number, data: any) => {
    const settings = await api.updateCommunitySettings(communityId, data)
    return settings
  }

  @action updateCommunity = async (communityId: number, data: object) => {
    await api.updateCommunity(communityId, data)
  }

  @action updateCommunities = async (communityId: number, formData: CommunityFormState) => {
    const data: CommunityUpdateReq = {
      id: communityId,
      name: formData.name,
      description: formData.description,
      status: formData.isEnable ? 1 : 0,
      sendSMS: formData.isSendSMS,
      sendEmail: formData.isSendEmail,
      enableSmsUnsubscribe: formData.enableSmsUnsubscribe,
    }
    await api.updateCommunities(communityId, data)
  }

  @computed
  get numberOfOrgs(): number {
    return Object.keys(this.orgs).length
  }

  @observable isGroupModalOpen: boolean = false

  addGroup = () => this.setIsGroupModalOpen(true)

  @observable isNewCommunityModalOpen: boolean = false

  openNewCommunityModal = () => {
    this.isNewCommunityModalOpen = true
  }

  closeNewCommunityModal = () => {
    this.isNewCommunityModalOpen = false
  }

  @action setIsGroupModalOpen = (state: boolean) => this.isGroupModalOpen = state

  @action setIsNewCommunityModalOpen = (state: boolean) => this.isNewCommunityModalOpen = state

  @action createNewCommunity = async (name: string) => {
    const res = await api.createCommunity(name)
    if (user.user != null && typeof res === 'number') {
      await api.updateUserCommunity(user.user, res, true)
      await this.initOrgs()
      this.setCurrentOrgId(res)
    }
  }

  @action updateUserShowInAlphon = async (orgId: number, is_showInAlphon: boolean) => {
    if (user.user?.user_id) {
      await api.updateUserAlphon(orgId, is_showInAlphon, user.user.user_id)
      this.orgs[orgId].is_showInAlphon = is_showInAlphon
      if (orgId == this.currentOrgId) {
        members.updateMyUserIsShowInAlphone(is_showInAlphon)
      }
    }
  }
  @action showHideUserCommunity = async (orgId: number, enabled: boolean) => {
    const enabledCommunities = this.data.filter(el => el.is_enabled)
    if (user.user?.user_id && (enabledCommunities.length > 1 || enabled)) {
      const res = await api.setUserCommunity(orgId, enabled)
      this.orgs[orgId].is_enabled = enabled
      const found = this.data.find(el => Number(el.id) === orgId)
      if (!enabled && orgId === Number(this.currentOrg.id)) {
        const alternateCommunity = enabledCommunities.find(el => Number(el.id) != orgId && el.is_enabled)
        if (alternateCommunity) {
          this.setCurrentOrgId(Number(alternateCommunity.id))
        }
      }
      if (found) {
        found.is_enabled = enabled
      }
      return res;
    }
  }
  @observable isAssociateModalOpen = false;
  @observable isMapSettingsModalOpen = false;
  @observable isCommunitySettingsModalOpen = false
  @observable providers: IObservableArray<Provider> = observable([]);
  @observable orgProvider: Provider | null = null;
  @action updateCommunityProvider = async (providerId: number) => {
    if (providerId === -1) return
    await api.updateCommunityProvider(providerId)
    const found = this.providers.find(el => el.providerId === providerId)
    if (found) {
      this.orgProvider = found
    }
  }
  @action setShowAssociateModal = (bool: boolean) => {
    this.isAssociateModalOpen = bool
  }

  @action setShowCommunitySettingsModal = (bool: boolean) => {
    this.isCommunitySettingsModalOpen = bool
  }

  setShowMapSettingsModal = (bool: boolean) => {
    this.isMapSettingsModalOpen = bool
  }

  @action getProviders = async (orgId: number) => {
    if (user.user.role === Role.ADMIN) {
      const providers = await api.getProviders()
      if ((!providers?.status || providers.status < 400) && !providers?.errors?.length) {
        runInAction(() => {
          this.providers.replace(providers.data || [])
        })
      }
    }
    if (user.user.role === Role.ADMIN) {
      const orgProvider = await api.getSelectedProvider(orgId)
      if (!orgProvider.errors?.length) {
        runInAction(() => {
          this.orgProvider = orgProvider.data || null
        })
      }
    }
  }
  @action deleteCommunityProvider = async () => {
    if (this.orgProvider?.providerId && this.currentOrgId && this.orgProvider.providerId !== -1) {
      await api.deleteCommunityProvider(this.currentOrgId, this.orgProvider?.providerId)
      runInAction(() => {
        this.orgProvider = null
      })
    }
  }

  @action clear = () => {
    this.setCurrentOrgId(undefined)
    this.setInitialized(false)
    this.orgs = {}
  }

}

const orgsStore = new OrgsStore()
export default orgsStore
