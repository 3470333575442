import React, { FC, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { ArrowDownward, ArrowUpward, Refresh, CenterFocusWeak } from '@material-ui/icons'
import { Appear, Icon, Row, Space, theme, Spinner } from 'ui'
import useStore from 'store'
import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Button, Typography, Switch } from '@material-ui/core'
import GoogleMapReact from 'google-map-react'
import MapDateTimePicker from './MapDateTimePicker'
import LogTable from './LogTable'
import * as moment from 'moment'
import {  userIsSuperAdmin, userIsDirector } from 'utils/roles'
import api from 'service/api'
import { ModuleEnum } from 'utils/types'


const Wrap = styled.div`
  flex: 1;
  background: rgba(0,0,0,0.05);
  overflow: auto;
  padding:0px;
`
const Title = styled.div`
  margin: 5px auto;
  position: relative;
  font-size: 30px;
  left: 135px;
`
const RefreshIcon = styled(Refresh)`
  color: white;
  font-size:30px;
`

const CenterFocusWeakIcon = styled(CenterFocusWeak)`
  color: white;
  font-size:30px;
`
const MapLegend = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index:999;
  background: white;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.3);
  border-radius: 2px;
`
const MapLegendItem = styled.div`
  margin-top: 5px;
`
const TableContent = styled.div`
  padding: 0pem 2pem 2rem 2pem;
`
const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
}
`
const GoTuReportButton = styled(Button)`
  background: ${theme.color.blueText};
  color: white;
  height: 42px;
  position:fixed;
  z-index: 999;
  left: 35px;
  top: 75px;

  &:hover {
    background: ${theme.color.darkBlue};
  }
`
const RefreshButton = styled(Button)`
  background: ${theme.color.blueText};
  position:fixed;
  z-index: 999;
  left: 110px;
  top: 75px;

  &:hover {
    background: ${theme.color.darkBlue};
  }
`

const PinButton = styled(Button)`
  background: ${theme.color.blueText};
  position:fixed;
  z-index: 999;
  left: 185px;
  top: 75px;

  &:hover {
    background: ${theme.color.darkBlue};
  }
`
const EmergencyToggle = styled.div`
  height: 42px;
  position:fixed;
  z-index: 999;
  left: 260px;
  top: 80px;
`

const SwitchEmergency = styled(Switch)`
 
`

const Map: FC = () => {    
    const { orgs: orgStore, mapStore, modulesStore } = useStore()
    const { t, i18n } = useTranslation('map')
    const { t: t2 } = useTranslation('mapSettings')

    const ref = useRef(null);
    const toolBarRef = useRef(null);
    const mapHeight = window.innerHeight - 300

    const [arrowDown, setArrowDown] = useState(true)
    const [checkedEmergency, setCheckedEmergency] = useState(false)

    useEffect(() => {
      const emergencyModule = modulesStore
        .communityModules
        .find(el => el.type === ModuleEnum.EMERGENCY_MANAGER)  

      if (emergencyModule == undefined) {
        return false
      }

      setCheckedEmergency(emergencyModule.enable)    
    }, [modulesStore.isEnabledEmergencyToggle])

    const onGoogleApiLoaded = ({ map, maps }) => {
        mapStore.setMap({ map, maps })

        maps.event.addListener(map, "zoom_changed", function () {
          try {
            if (orgStore.currentOrgId) {
              const zoom = this.getZoom();
              const key = `map_center${orgStore.currentOrgId}`
  
              const mapCenterCoordsStr = localStorage.getItem(key)
  
              if (!mapCenterCoordsStr) {
                localStorage.setItem(key, JSON.stringify({ lng: null, lat: null, zoom }))
              } else {
                const  mapCenterCoords = JSON.parse(mapCenterCoordsStr)
                mapCenterCoords['zoom'] = zoom
                localStorage.setItem(key, JSON.stringify(mapCenterCoords))
              }
            }
          } catch(e) {
            console.error('Error parse')    
          }
        });
        
        maps.event.addListener(map, "center_changed", function() {
          try {
            if (orgStore.currentOrgId) {
              const center = this.getCenter();
              const lat = center.lat();
              const lng = center.lng();
              const key = `map_center${orgStore.currentOrgId}`
  
              const mapCenterCoordsStr = localStorage.getItem(key)
  
              if (!mapCenterCoordsStr) {
                localStorage.setItem(key, JSON.stringify({ lng: null, lat: null, zoom: null }))
              } else {
                const  mapCenterCoords = JSON.parse(mapCenterCoordsStr)
                mapCenterCoords['lat'] = lat
                mapCenterCoords['lng'] = lng
                localStorage.setItem(key, JSON.stringify(mapCenterCoords))
              }
            }
          } catch(e) {
            console.error('Error parse')    
          }
        });

        const timestamp = new Date().valueOf()
        mapStore.getLocationData(`${timestamp}`)
    }

    const scrollToReport = () => {
        if (arrowDown) {
            ref.current?.scrollIntoView({behavior: 'smooth'});
        } else {
            toolBarRef.current?.scrollIntoView({block: 'start', behavior: 'smooth'});
        }
    };

    const filterAplied = () => {
      if (orgStore.currentOrgId) {
        const timestamp = new Date().valueOf()
        mapStore.getLocationData(`${timestamp}`)
      }
    }

    const refreshMap = () => {
      if (orgStore.currentOrgId) {
        const data = mapStore.filterDates 
        data['to'] = moment();
    
        mapStore.filterDates = {
          from: data.from,
          to: data.to
        }

        const timestamp = new Date().valueOf()
        mapStore.getLocationData(`${timestamp}`, 1, true)
      }
    }

    const handleScroll = event => {
      if(event.currentTarget.scrollTop < 20) {
        setArrowDown(true)
      } else {
        setArrowDown(false)
      }
    };

    const toggleVisibleEmergency = async (emergencyEnabled: boolean | null) => {
      if (orgStore.currentOrgId) {

        if (userIsSuperAdmin()) {
          const emergencyModule = modulesStore
            .communityModules
            .find(el => el.type === ModuleEnum.EMERGENCY_MANAGER)  
          
          if (emergencyModule == undefined) {
            return false
          }

          await modulesStore.updateModule({
            module_id: emergencyModule.module_id,
            type: ModuleEnum.EMERGENCY_MANAGER,
            external_url: null,
            title: null,
            description: null,
            enable: emergencyEnabled == null ? false : emergencyEnabled,
            community_ref: emergencyModule.community_ref,
            directorPermission: emergencyModule.directorPermission,
            icon_color: null,
            icon_text: null,
            icon_name: null,
            icon_text_settings: null,
            icon: null,
            org_id: emergencyModule.org_id,
            provider: null,
            share: emergencyModule.share,
            information: null,
            shareEmail: null,
          })

          setCheckedEmergency(emergencyEnabled)
          await modulesStore.getCommunityModulesAndFeatures(orgStore.currentOrgId)    

        } else if(userIsDirector()) {
          const emergencyModule = modulesStore
            .communityDirectorModules
            .find(el => el.type === ModuleEnum.EMERGENCY_MANAGER)  

          if (emergencyModule == undefined) {
            return false
          }

          await modulesStore.updateModuleByDirector({
            module_id: emergencyModule.module_id,
            type: ModuleEnum.EMERGENCY_MANAGER,
            external_url: null,
            title: null,
            description: null,
            enable: emergencyEnabled == null ? false : emergencyEnabled,
            community_ref: emergencyModule.community_ref,
            directorPermission: emergencyModule.directorPermission,
            icon_color: null,
            icon_text: null,
            icon_name: null,
            icon_text_settings: null,
            icon: null,
            org_id: emergencyModule.org_id,
            provider: null,
            share: emergencyModule.share,
            information: null,
            shareEmail: null,
          })

          setCheckedEmergency(emergencyEnabled)    
          await modulesStore.getCommunityDirectorModules(orgStore.currentOrgId)    
        }
      }
    }
   
    return (
        <Wrap  onScroll={handleScroll}>
          <ToolBar ref={toolBarRef}>
            <MapDateTimePicker onValueChanged={filterAplied}/>    
            <Title>דיווחי חרום בישוב</Title>
            <GoTuReportButton onClick={scrollToReport}>
                {arrowDown ? t`goToTable` : t`goToMap`}
            </GoTuReportButton>

            <RefreshButton  onClick={() => { refreshMap() }}>
              <RefreshIcon/>
            </RefreshButton>
            {modulesStore.isEnabledEmergencyToggle && <EmergencyToggle>{t2`showEmergencyBtn`}<SwitchEmergency color='primary' checked={checkedEmergency} onChange={e => { toggleVisibleEmergency(e.target.checked) }} /></EmergencyToggle>}
            
            {orgStore.settings && orgStore.settings.lat && orgStore.settings.lng && orgStore.settings.zoom && <PinButton  onClick={() => { 
              if (mapStore.map && mapStore.maps) {
                const mapSettings = orgStore.settings
                mapStore.map.panTo(new mapStore.maps.LatLng(mapSettings.lat, mapSettings.lng));
                mapStore.map.setZoom(mapSettings.zoom);
              }
             }}>
              <CenterFocusWeakIcon/>
            </PinButton>}
          </ToolBar>
          <div style={{ height: `${mapHeight}px`, position: 'relative' }}>
          <MapLegend>
            <MapLegendItem><img src="/marker.png"/><span> - קריאת מצוקה</span></MapLegendItem>   
            <MapLegendItem><img src="/marker2.png"/><span> - קריאה מחוץ לישוב</span></MapLegendItem>
            <MapLegendItem><img src="/marker3.png"/><span> - קריאה בישוב</span></MapLegendItem>
          </MapLegend>  
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY, libraries: ["geometry"] }}
            defaultCenter={mapStore.getDefaultLocation()}
            defaultZoom={11}
            options={function (maps) { 
                return { 
                  mapTypeControl: true,
                  mapId: '4807733f13d4eea5',
                  mapTypeControlOptions: {
                  style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                  mapTypeId: 'satellite',
                  mapTypeIds: ["satellite", "roadmap"],
                },
           } }}
            onGoogleApiLoaded={onGoogleApiLoaded}
          >
          </GoogleMapReact>
          </div>
          <TableContent ref={ref}>
            {mapStore.loading ? <Spinner /> : <LogTable />}
           </TableContent> 
        </Wrap>
    );
}


export default observer(Map)