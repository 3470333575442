import { User } from "./models";
import { OperationType } from './operation'
export interface UserCommunity {
  id: number
  name: string
  groups: UserGroup[]
  is_director: boolean
  is_operator: boolean
  is_showInAlphon: boolean,
  is_enabled: boolean,
  mcEntities: McEntity[] | null
  allowShare: boolean | null
  sendEmail: boolean | null
  sendSMS: boolean | null
  enableSmsUnsubscribe: boolean | null
  status: number
  brandId: number
  description: string | null
}

export interface Brand {
  id: number
  brandName: string
  siteUrl: string
  status: number
}


export interface Description {
  category: string
  color: string
  fabColor: string
  icon: string
  iconColor: string
  iconName: string
  subCategory: string
  subCategoryKey: string,
  sound?: string
}
export interface Group {
  community_id: number
  description: Description | string
  duplex: number
  group_is_favourite: boolean
  group_is_member: boolean
  group_is_sender: boolean
  group_is_subscriber: boolean
  icon?: any
  id: number
  is_organizer?: boolean //in support groups i suppose
  members: any
  name: string
  orgainzers: number
  parent_id?: number
  type: GroupType
  mcEntity: string | null,
  mcEntityID: number | null,
  providerId: number | null,
  providerName: string | null,
  sendSMS: boolean,
  sendEmail: boolean,
  allowShare: boolean,
  groupLabels: Array<string> | null
}
export interface McEntity {
  name: string,
  id: number,
  smsCounter: number
}


export interface AlarmMessage {
  guid: string,
  communityId: number | null,
  messageId?: number 
  deviceId?: string | null,
  deviceDetails: string,
  note?: string,
  accuracy: number | null,
  lat: number | null,
  lng: number | null,
  address?: string
}

export interface CommunityUpdateReq {
  id: number
  name: string
  description: string
  status: number
  sendSMS: boolean
  sendEmail: boolean
  enableSmsUnsubscribe: boolean
}

export interface UserGroup {
  community: number,
  group: Group
  is_favourite: boolean
  is_hide?: boolean //returned in getGroupMembers
  is_member: boolean
  is_organizer: boolean
  is_sender: boolean
  allowShare: boolean
  sendEmail: boolean
  sendSMS: boolean
  is_subscriber: boolean
  // parentOrganizer: number,//CHECK this one
  user: number
  userData?: User
}


export interface UserGroupUpdateReq extends UserGroupReq {
  // group: number
}

export interface UserGroupCreateReq extends UserGroupReq {
}
interface UserGroupReq {
  member: boolean
  subscriber: boolean
  duplex: number
  favourite: boolean
  guid: string
  community: number
  id: number
  name: string
  description: string
  sender: boolean
  SendSMS: boolean,
  SendEmail: boolean,
  allowShare: boolean,
  icon?: string | null,
  organizer?: boolean,
  hide?: boolean,
  sms?: boolean,
}

export interface SendApiUser {
  nick: string,
  family: string,
  given: string,
  emails: string[],
  phones: string[],
}
export interface SendApiUserReq extends SendApiUser {
  guid: string,
  id: number,
  community: number
  user: number | undefined
  member: number
  sender: number
  organizer: number
  subscriber: number
  favourite: number

}
export interface CreateCommunityReq {
  id: number,
  guid: string,
  name: string,
}

export interface Community {
  community: number
  name: string
}

export enum StrategyType {
  text = 'TEXT',
  media = 'MEDIA',
  survey = 'QS',
  pdf = 'PDF'
}

export type ID = number


export interface MessageResponseOp {
  messageId: number
  status: number
  response: number
}

export interface MessageOp extends Op {
  group: number
  sender: number
  type: string
  payload: string
  expiry?: string
  guid: string
  message?: number
  schedule?: string
  CreateNewIssue?: boolean
  files?: any[]
  locationRequest?: Boolean
}
export interface DeletePushOp extends Op {
  op: OperationType,
  id: number,
  user: number,
  type: number
}

interface Op {
  op: OperationType
  id?: number

}

export interface PayloadRequest {
  files: any,
  payload: any,
  type: any,
  blob: any
}
export enum deliveryType {
  MOBILE = 3,
  MAIL = 2,
  SMS = 1,
  MOBILE2 = 4
}
export interface SurveyAnswer {
  id: string
  text: string
  color: number
}

export enum MessageDialogMode {
  Regular = 'regularMessage',
  Survey = 'surveyMessage'
}

export enum StatusType { MUTED = 1, OUTGOING = 2, SENT = 3, FAILED = 4, RECEIVED = 5, READ = 6, RESPONSED = 7 }

export enum ModuleEnum {
  DELIVERY = 'delivery',
  SLIKA = 'slika',
  PORTAL = 'portal',
  THIRD = 'third',
  SUPPORT = 'support',
  ID_QR_CODE = 'IDQRCode',
  EMERGENCY_MANAGER = 'emergencyManager',
  KEHILANET = 'Kehilanet app'
}

export interface ModuleType {
  module_id: number | null
  type: ModuleEnum
  external_url: string | null
  title: string | null,
  enable: boolean,
  directorPermission: boolean
  description: string | null
  community_ref: number | null | undefined
  icon_color: string | null
  icon_text: string | null
  icon_name: string | null | undefined
  icon_text_settings: string | null
  icon: string | null
  org_id: string | null
  provider: string | null
  share: boolean
  information: string | null
  shareEmail: string | null
}

export enum ProviderEnum {
  MAOF = 'maof',
  NONE = 'none',
  SLIKA = 'slika',
  MEKOME = 'mekome',
  MEKOME_QR = 'mekomeQR',
  KEHILANET = 'kehilanet'
}
export enum GroupType {
  REGULAR = 'regular',
  SUPPORT = 'support',
  ENTITY = "entity"
}
export interface Provider {
  providerId: number, providerName: string
}
// export interface ThirdItem {
//   module_id: number
//   type: string
//   external_url: string,
//   title: string
//   description: string
//   // icon:string
//   // data:string
//   icon_color: string
//   icon_text: string
//   // icon_name: string
//   // icon_text_settings:string
// }

export enum FeatureIds {
  MESSAGING = 1,
  PORTAL,
  SERVICE,
  BUDGET,
  CONTACTS,
  MAP,
}

export interface FeatureData {
    communityId: number;
    enabled: boolean;
    featureName: string;
    id: FeatureIds;
    status: number;
}

export interface Feature {
    communityId: number;
    features: FeatureData[];
}