import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'

import MessagesDialog from 'components/Messages/MessagesDialog'
import { GroupType } from 'utils/types'

import Page from 'core/Page'
import { Row, Space, theme, IconButton, Image, Text, Spinner, Appear, Icon as UiIcon, Button } from 'ui'
import { Add } from '@material-ui/icons'
import useStore, { messages } from 'store'
import { useTranslation } from 'react-i18next'
import { Tooltip, Icon } from '@material-ui/core'
import MessagePopover from 'components/Messages/MessagePopover'
import MessageDetailsModal from 'components/Messages/MessageDetailsModal'
import { canSendGroupMessage, canSendAndSeeScheduledGroupMessages } from 'utils/roles'
import MessagesFeed from 'components/Messages/MessagesFeed/MessagesFeed'
import ViewerModal from '../../../components/Messages/ViewerModal'
import Sidebar from 'core/Sidebar'
import EmptyComponent from 'components/Empty'
import EmptyGroups from 'assets/icons/emptyGroups.svg'
import { isAfter } from 'date-fns'
import MessageScheduleModal from 'components/Messages/MessageScheduleModal'
import { Path } from 'core/Routes'
import { useHistory } from 'react-router'
import Alarm from 'assets/icons/alarm.svg'
import { isTotzeret, getTotzeretColorsByCategory } from 'service/whiteLable'

const isMobile = window.innerWidth < 768

const ENTITY_LINK = "https://forms.galil-elion.org.il/express/?mekomi=N.-Tth7!TJV9"

const Content = styled.div<{ color: string }>`
  /* padding: 1px 1rem; */
  display:flex;
  flex:1;
  background-color:${p => p.color};
  border-radius: 0 0 5px 5px;
  /* overflow: auto; */
  border-top-left-radius:20px;
  border-top-right-radius:20px;
`
const SpinnerContainer = styled.div`
  height:90vh;
  align-items:center;
  justify-content:center;
  width:100%;
  display:flex;
    `

const Info = styled.div`
  background: ${p => p.color || 'var(--light-gray)'};
  color: var(--text);
  padding:10px 10px;
  padding-right:15px;
  ${isTotzeret ? '' : 'padding-bottom:20px;'}
  /* border-radius: 5px 5px 0 0; */
  position:relative;
`
const InfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const HeaderTextContainer = styled.div`
  display:flex;
  flex-direction:column;
  cursor:pointer;
  ${isTotzeret ?' margin-right: 15px;' : ''}
`

const IconBg = styled.div`
  height:60px;
  width:45px;
  ${p => isTotzeret ? '' : ('background: ' + p.color + ';')}
  display: flex;
  align-items: ${p => isTotzeret ? 'center;' : 'flex-end;'}
  justify-content: center;
  padding:0.33rem 0;
  border-radius: 8px;
  ${isTotzeret ? '2px;' : 'margin-left:1rem;'}
  cursor:pointer;
`
const Container = styled.div`
  flex: 1;
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(12, 0, 51, 0.1);
  flex-direction:row;
  display:flex;
`
const FeedContainer = styled.div<{ color: string }>`
  display: flex;
  flex:1;
  background-color:${p => p.color};
  ${!isTotzeret ? 'border-top-left-radius:20px;' : ''}
  ${!isTotzeret ? 'border-top-right-radius:20px;' : ''}
`
const Wrap = styled.div`
display:flex;
flex-direction:column;
  flex: 1;
`
const EmptyGroupContainer = styled.div`
display:flex;
  flex: 1;
  /* align-items:center; */
  justify-content:center;
  background:var(--back);

`

const EntityContainer = styled.div`
  display:flex;
  flex:1;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  white-space:pre-line;
`

const TextEntity = styled(Text)`
  padding-top: 30px;
  padding-bottom: 10px;
  width: 500;
`

const ImgArrow = styled.div<{ color: string }>`
  border-style: solid;
  border-top-width: 7px;
  border-top-color: transparent;
  border-right-width: 8px;
  border-right-color: ${(p) => p.color};
  border-bottom-width: 7px;
  border-bottom-color: transparent;
  border-left-width: 0;
  border-left-color: transparent;
`

const ImgArrowContainer = styled.div<{ color: string }>`
    height: 60px;
    width: 2px;
    background-color: ${(p) => p.color};
`

const Messages: FC = () => {
  const { t } = useTranslation('messages')
  const { orgs, groups, messages, user } = useStore()
  const showPlus = canSendGroupMessage()
  const { location } = useHistory()
 
  const groupsAreEmpty = (location.pathname === Path.SERVICECALL) ? (groups.currentSupportGroups.length === 0) : (groups.filteredCurrentUserGroups.length === 0)
  const isEntityService = groups.currentGroupType === GroupType.ENTITY && location.pathname === Path.SERVICECALL
  const serviceCallType = groups.currentGroup?.group?.type === GroupType.SUPPORT || isEntityService
  const showPlusServiceCall = (serviceCallType && messages.messageFeedSections.length === 0) ? false : true

  const showSchedule = canSendAndSeeScheduledGroupMessages() && !serviceCallType
  const scheduleMessageCount = messages.messageFeedSections.reduce((a, b) => (a + b.messages.filter(el => !isAfter(new Date(), el.message.schedule || 0)).length), 0)
  // React.useEffect(() => {
  //   if (currentGroupId && currentGroupId < 0 && currentGroup) {
  //     getGroupMembers(currentGroup.parent_id)//TODO fix typescript
  //   }
  // }, [currentGroupId])
  const mainColor = () => {
    if (isTotzeret) {
      return getTotzeretColorsByCategory(groups.currentGroup?.group?.description?.subCategoryKey).secondary
    }
    if(isEntityService) {
      return theme.color.entityBlue
    } else {
      return groups.currentGroup?.group?.description?.color || theme.color.defaultThemeColorGray
    }
  }

  if (groups.loadingGroups || messages.lastMessagesLoading) {
    return <SpinnerContainer><Spinner /></SpinnerContainer>
  }

  const getEntityComponent = () => {
    const {family_name, given_name, phones} = user.user
      return <EntityContainer>
        <TextEntity
          align="center"
          textcolor={theme.color.black + 99}
          size='xxl'
          weight='medium'>{t`serviceCall:entity_message`}</TextEntity>
        <Space />
        <Button
          onClick={() => {
            window.open(`${ENTITY_LINK}&first_name=${given_name}&last_name=${family_name}&mobile_phone=${phones[0]}`, "_blank")
          }}
          size="large"
          style={{ color: 'white', backgroundColor: mainColor() }}
          variant="contained"
          endIcon={<Add />}
        >
          {t`serviceCall:open_call`}
        </Button>
      </EntityContainer>
  }

  const renderBodyMessage = () => {
    if(isEntityService) {
      return getEntityComponent()
    }
    if(messages.isLoading) {
      return <SpinnerContainer><Spinner /></SpinnerContainer>
    } else {
      return <MessagesFeed />
    }
  }

  return (
    <Container>
      {!isMobile && <Sidebar />}
      { groupsAreEmpty ? (
        <EmptyGroupContainer>
          <EmptyComponent
            icon={<Image src={EmptyGroups} />}
            title={t`emptyGroup:groups_list_title`}
            description={t`emptyGroup:groups_list_description`} />
        </EmptyGroupContainer>
      ) : (
          <Wrap>
            <Info color={mainColor()}>
              <InfoHeader>
                <IconBg
                  onClick={() => !isEntityService && groups.openMembersModal()}
                  color={isEntityService && theme.color.entityRed || groups.currentGroup?.group?.description?.iconColor || '#d9d9d9'}>
                  {isEntityService ? <img src={Alarm} /> :  <UiIcon size={30} name={groups.currentGroup?.group?.description?.icon || 'default'} />}
                </IconBg>
                {isTotzeret && <ImgArrowContainer color={groups.currentGroup?.group?.description?.iconColor || '#d9d9d9'} />}
                {isTotzeret && <ImgArrow color={groups.currentGroup?.group?.description?.iconColor || '#d9d9d9'} />}
                <HeaderTextContainer onClick={() => !isEntityService && groups.openMembersModal()}>
                  {!isTotzeret && <Text
                    style={{ lineHeight: 1, marginTop: 5 }}
                    textcolor={theme.color.whiteBackground + 'CC'}
                    weight='normal'
                    size={'md'}
                  >{isEntityService ? groups.mcEntityGroup?.name : orgs.currentOrg?.name}</Text>}
                  {isEntityService ? 
                    <Text
                      textcolor={isTotzeret ? '#3a4347' : theme.color.whiteBackground}
                      weight='medium'
                      size={'xxl'}
                    >מוקד 106</Text>
                  : 
                    <Row style={{ justifyContent: 'flex-start', }}>
                      {serviceCallType && groups.currentGroup?.group.description.UserFullName && groups.currentGroup?.group.description.pname ?
                        <>
                          <Text
                            textcolor={isTotzeret ? '#3a4347' : theme.color.whiteBackground}
                            weight='medium'
                            size={'xxl'}
                          >{`${groups.currentGroup?.group?.description?.pname} | \xa0`}</Text>
                        
                          <Text
                            textcolor={isTotzeret ? '#3a4347' : theme.color.whiteBackground}
                            weight='medium'
                            size={'xxl'}
                          >{groups.currentGroup?.group?.description?.UserFullName}</Text>
                        </>
                        : <Text
                          textcolor={isTotzeret ? '#3a4347' : theme.color.whiteBackground}
                          weight='medium'
                          size={'xxl'}
                        >{groups.currentGroup?.group?.name}</Text>
                      }
                    </Row>
                  }
                </HeaderTextContainer>
              </InfoHeader>
              {showPlusServiceCall && showPlus && <MessagePopover
                showSchedule={showSchedule}
                scheduleCount={scheduleMessageCount}
                serviceCallType={serviceCallType}
              />}
            </Info>
           
            <div style={{ display: 'flex', flex: 1, backgroundColor: isTotzeret ? '#f4f4f2' : mainColor(), overflow: 'auto' }}>
              <Content color={isEntityService ? theme.color.entityBodyBlue : isTotzeret ? '#f4f4f2' : 'white'}>
                <FeedContainer color={groups.currentGroup?.group?.description?.color + 33 || 'rgba(0,0,0,0.05)'}>
                  {renderBodyMessage()}
                </FeedContainer>
              </Content>
            </div>
          </Wrap>
        )}
      {messages.isModalOpen && <MessagesDialog />}
      <MessageDetailsModal />
      <MessageScheduleModal />
      <ViewerModal />
    </Container>
  )
}

export default observer(Messages)
